import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { size } from 'polished';
import Img from 'gatsby-image';

const cardPeople = ({ content }) => {
    const { image, name, jobTitle, groups, quote } = content;
    let items = [];

    if(groups) {
        for (const [index, { group }] of groups.entries()) {
            if(group) {
                items.push(
                    <span key={index}>{group.title[0].text}</span>
                );
            }
        }
    }

    const showDescription = () => {
        if (quote && quote[0].text > 0) {
            return (
                <SCardDescription>{RichText.render(quote)}</SCardDescription>
            );
        }
    };

    return (
        <SCard>
            <SCardTop>
                <SCardImage>
                    <Img alt="Medewerker Madelief" fixed={image} />
                </SCardImage>
                <SCardContent>
                    <SCardName>{name}</SCardName>
                    <SCardJobTitle>{jobTitle}</SCardJobTitle>
                    <SCardGroup>{items}</SCardGroup>
                </SCardContent>
            </SCardTop>

            {showDescription()}
        </SCard>
    );
};

const SCard = styled.div`
    height: 100%;
    padding: 16px;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 4px;
    background-color: var(--color-white);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
`;

const SCardTop = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const SCardImage = styled.div`
    ${size('60px', '60px')}
    display: block;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

    .gatsby-image-wrapper {
        max-width: 100%;
    }
`;

const SCardContent = styled.div`
    color: var(--color-gray-dark);
`;

const SCardName = styled.div`
    margin-bottom: 4px;
    color: var(--color-light-black);
    font-weight: 700;
`;

const SCardJobTitle = styled.div`
    margin-bottom: 2px;
`;

const SCardGroup = styled.div`

    span + span {

        &::before {
            content: ', ';
        }
    }
`;

const SCardDescription = styled.div`
    width: 100%;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--color-gray-lighter);
    width: 100%;

    a {
        text-decoration: underline;
        color: var(--color-secondary);
    }
`;

export default cardPeople;

cardPeople.propTypes = {
    content: PropTypes.object.isRequired,
};
