import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import Grid from '../components/grid/grid';
import GridCell from '../components/grid/gridCell';
import PanoramaDefault from '../components/parts/panoramas/panoramaDefault';
import Intro from '../components/parts/content/intro';
import CardPeople from '../components/parts/cards/cardPeople';
import SeoBlock from '../components/parts/content/seo';
import breakpoints from '../styles/settings/breakpoints';

const IndexPage = ({ data }) => {
    const items = [];
    const prismicData = data.prismic.team;

    const panorama = {
        title: prismicData.panorama_title[0].text,
        text: prismicData.panorama_text[0].text,
        image: {
            src: prismicData.panorama_imageSharp.childImageSharp.fluid,
            alt: prismicData.panorama_image.alt
        }
    };

    const intro = {
        title: prismicData.intro_title?.[0].text,
        text: prismicData.intro_text
    };

    const { employees } = prismicData;

    const seo = {
        title: prismicData.seo_title[0].text,
        text: prismicData.seo_text
    };

    for (const [index, { employee }] of employees.entries()) {
      
        if(employee) {  
            items.push(
                <GridCell cellSizes={{ small: '100%', medium: '50%', large: `${(1 / 3) * 100}%` }} key={index}>
                    <CardPeople
                        content={{
                            image: employee.avatarSharp.childImageSharp.fixed,
                            name: employee.name,
                            jobTitle: employee.jobtitle,
                            groups: employee.groups,
                            quote: employee.quote
                        }}
                    >
                        {index}
                    </CardPeople>
                </GridCell>
            );
        }
    }

    return (
        <Layout>
            <SEO title={panorama.title} />
            <PanoramaDefault
                background={false}
                alignment="center"
                content={{
                    image: panorama.image,
                    title: panorama.title,
                    text: panorama.text
                }}
            />
            <Contain>
                <Intro intro={intro} />
                <SCards>
                    <Grid gutter="normal">{items}</Grid>
                </SCards>
                <SeoBlock content={seo} />
            </Contain>
        </Layout>
    );
};

const SCards = styled.div`
    margin-top: 40px;
    margin-bottom: 100px;

    > div > div {
        margin-top: 20px;
    }

    ${breakpoints.medium} {
        > div > div {
            margin-top: 40px;
        }
    }
`;

export default IndexPage;

export const pageQuery = graphql`
    query {
        prismic {
            team(lang: "nl-nl", uid: "het-team") {
                panorama_image
                panorama_imageSharp {
                    childImageSharp {
                        fluid(maxWidth: 2560, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                panorama_text
                panorama_title
                intro_title
                intro_text
                employees {
                    employee {
                        ... on PRISMIC_Employee {
                            name
                            jobtitle
                            quote
                            groups {
                                group {
                                    ... on PRISMIC_Single {
                                        title
                                    }
                                }
                            }
                            avatar
                            avatarSharp {
                                childImageSharp {
                                    fixed(width: 60, quality: 90) {
                                        ...GatsbyImageSharpFixed_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
                seo_title
                seo_text
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.object
};
